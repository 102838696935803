@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital@1&family=Roboto&display=swap');
*{
  max-width: 100vw;
}
/* Side Scroll Bar-------------------------------------------------------------------------*/
::-webkit-scrollbar-track
{
    box-shadow: inset 0 0 6px rgba(0,0,0,0.9);
    border-radius: 10px;
    background-color: var(--primary-dark-grey);
}
::-webkit-scrollbar
{
    width: 10px;
    background-color: var(--primary-grey);
}
::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background-color: var(--blue);
    background-image: -webkit-linear-gradient(90deg,transparent rgba(0, 0, 0, 0.4) 50%, transparent, transparent)
}
h1,
h2,
h3,
h4,
h5{
  font-family: 'Montserrat', Arial, Helvetica, sans-serif !important;
}
p{
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
}
:root{
  --sucess-green: green;
  --green: #04e93db2;
  --blue: #0343bb;
  --lightGrey: #f4f4f4; 
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --icon-blue: #072bf6;
  --error-red: red;
}