.services{
    padding-bottom: 2rem;
  }
  .services h1,
  h2{
    color: var(--blue);
  }
  .icon__color{
    color: var(--blue);
  }
  .break{
    visibility: hidden;
  }
  @media only screen and (max-width: 568px){
  .break{
    background: #fff;
    max-width: 90vw;
    visibility: visible;
  }
}