@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital@1&family=Roboto&display=swap);
.header__wraper{
    position: relative;
    background-image: url(/static/media/wood-desk.6757664f.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100vw;
    opacity: 1;
  }
  .header__wraper .logo{
    position: fixed; 
    left: 0.8rem;
    top: 0;
    z-index: 100;
    margin: 0;
    padding: 0;
  }
  .main__info{
    
    display: -webkit-flex;
    
    display: flex;
    position: absolute;
    top:0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    z-index: 1;
  }
  .main__info > h1{
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
  }
  /*********************TYPED TEXT********************************/
  .typed__text{
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    color:#fff;
    
  }
  /*****REACT PARTICLES**********************/
  .tsparticles-canvas-el{
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 1;
  }
/*******MEDIA QUERIES***********************/
@media only screen and (max-width: 568px) {
    /*Header*****************************/
    .main__info h1{
      font-size: 32px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
    }
    .header__wraper .logo{
      height: 175px;
      width: 175px;
      top: 0 !important;
    }
  
    .typed__text{
      font-size: 1.6rem;
      font-weight: bolder;
      text-transform: uppercase;
      color: #05fb42;
    }
    .tsparticles-canvas{
      display: none !important;
    }
}
*{
  max-width: 100vw;
}
/* Side Scroll Bar-------------------------------------------------------------------------*/
::-webkit-scrollbar-track
{
    box-shadow: inset 0 0 6px rgba(0,0,0,0.9);
    border-radius: 10px;
    background-color: #666666;
    background-color: var(--primary-dark-grey);
}
::-webkit-scrollbar
{
    width: 10px;
    background-color: #ccd1d9;
    background-color: var(--primary-grey);
}
::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background-color: #0343bb;
    background-color: var(--blue);
    background-image: -webkit-linear-gradient(90deg,transparent rgba(0, 0, 0, 0.4) 50%, transparent, transparent)
}
h1,
h2,
h3,
h4,
h5{
  font-family: 'Montserrat', Arial, Helvetica, sans-serif !important;
}
p{
  font-family: 'Roboto', Arial, Helvetica, sans-serif !important;
}
:root{
  --sucess-green: green;
  --green: #04e93db2;
  --blue: #0343bb;
  --lightGrey: #f4f4f4; 
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --icon-blue: #072bf6;
  --error-red: red;
}
.tech__heading{
    text-transform: uppercase;
    color: var(--blue);
    text-indent: 10px;
}
.tech__icons{
    padding: 10px;
    transition: all .2s ease-in-out;
    
}
.tech__icons:hover{
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}
.services{
    padding-bottom: 2rem;
  }
  .services h1,
  h2{
    color: var(--blue);
  }
  .icon__color{
    color: var(--blue);
  }
  .break{
    visibility: hidden;
  }
  @media only screen and (max-width: 568px){
  .break{
    background: #fff;
    max-width: 90vw;
    visibility: visible;
  }
}
.contact{
    background: #fff;
    padding: 2.5rem;
  }
  .contact h1{
    color: var(--blue)
  }
  .contact p{
    color: #000;
  }
  .contact input, .contact textarea{
    width: 100%;
    box-sizing: border-box;
    background: none;
    resize: none;
    border: 0;
    border-bottom: 0.125rem solid var(--primary-dark-grey);
    border-radius: 0;
    color: #000;
    outline: 0 !important;
    box-shadow: none !important;
    margin-bottom: 1rem;
  }
  .contact .container{
    padding: 3rem 0;
  }
  .contact input:focus,
  .contact textarea:focus{
    background: transparent;
    color: #000;
  }
  .contact textarea{
   height: 12.465rem !important;
  }
  .contact__btn{
    margin: 1rem 0 0 0;
    width: 100%;
    background: none;
    border: 0.125rem solid var(--primary-dark-grey);
  }
  .contact__btn:hover{
    background: var(--blue);
  }
  .error__message {
    color: var(--error-red);
    
    text-align: center;
  }
  
  .success__message {
    font-weight: 700;
    color: var(--sucess-green);
  }
.footer{
    background: var(--lightGrey);
  }
  .footer .container{
    padding: .5rem;
  }
  .footer img{
    width: 7.5rem !important;
    height: 8rem !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .footer h5{
    font-family: 'Montserrat', Arial, Helvetica, sans-serif !important;
  }
  .footer p{
    margin: 0;
    padding: 0;
  }
  a{
    font-size: 1.6rem;
    text-decoration: none;
    opacity: 1;
    transition: 0.6s;
  }
  a:hover{
    opacity: 0.5;
  }

  @media only screen and (max-width: 568px){

    .footer .container{
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-justify-content: center !important;
              justify-content: center !important;
      text-align: center !important;
    }
    .footer img{
      width: 10rem !important;
      height: 10rem !important;
      margin: 0 !important;
      padding: 0 !important;
    }
    .footer a{
        font-size: .9rem !important;
    }
  }
  @media only screen and (max-width: 768px){
  
    .footer a {
      font-size: 1.2rem;
      margin-left: .4rem;
      padding-right: .8rem !important;
    }
}
.photo__container{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
  .profile__pic{
    width: 18rem;
    border: 1px solid var(--blue);
    border-radius: 50%;
    padding: 0.3rem;
  }
  .about__heading{
    text-transform: uppercase;
    color: var(--blue);
  }
  .about__me{
    text-indent: 25px;
  }
/*MEDIA QUERIES**********************************/
@media only screen and (max-width: 568px){
  .profile__pic{
    width: 13.4rem;
  }
}


h3{
    color: var(--blue);
}

.badge{
    background-color: var(--blue);
    padding: 10px;
}
.project__wrapper{
    padding: 3rem 0;
  }
  .project__wrapper h1{
    color: var(--blue);
  }
  .project__image{
    max-width: 15rem;
    height: 9rem;
    padding: 0 0.5rem;
  }
  .project__image__box{
    position: relative;
    margin: 1rem;
  }
  .overflow{
    position: absolute;
  }
  
  .project__icon{
    position: absolute;
    opacity: 0;
    margin-left: -8rem;
    margin-top: 3.5rem;
    color: var(--icon-blue);
    font-size: 2.4rem;
    font-weight: 700;
  }
  
  .project__image__box:hover .project__icon{
    opacity: 90%;
    transition: 0.3s ease-in-out;
    cursor: pointer;
  }
  .project__image__popup{
    width: 27rem;
    padding: 0 0.5rem;
  }
  .hyper__link{
    cursor: pointer;
    text-decoration: none !important;
    color: var(--blue);
    font-size: 1.2rem;
    display: inline-block;
  }
  .hyper__link:hover{
    cursor: pointer;
    text-decoration: none !important;
    color: var(--green);
  }
  /*MEDIA QUREIES******************************/
  @media only screen and (max-width: 568px){
  .project__image__popup{
    width: 100%; 
  }
}
@media only screen and (max-width: 568px){
  .break{
    background: #fff;
    max-width: 90vw;
    visibility: visible;
  }
}

/**Media QUERIES for projects on landscape mode*/
@media(max-height: 500px){
  .popupbox__wrapper{
    max-height: 100vh;
  }
  .project__image{
    max-width: 80%;
  }
  .project__image__popup{
    width: 12%; 
  }
}
