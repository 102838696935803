.project__wrapper{
    padding: 3rem 0;
  }
  .project__wrapper h1{
    color: var(--blue);
  }
  .project__image{
    max-width: 15rem;
    height: 9rem;
    padding: 0 0.5rem;
  }
  .project__image__box{
    position: relative;
    margin: 1rem;
  }
  .overflow{
    position: absolute;
  }
  
  .project__icon{
    position: absolute;
    opacity: 0;
    margin-left: -8rem;
    margin-top: 3.5rem;
    color: var(--icon-blue);
    font-size: 2.4rem;
    font-weight: 700;
  }
  
  .project__image__box:hover .project__icon{
    opacity: 90%;
    transition: 0.3s ease-in-out;
    cursor: pointer;
  }
  .project__image__popup{
    width: 27rem;
    padding: 0 0.5rem;
  }
  .hyper__link{
    cursor: pointer;
    text-decoration: none !important;
    color: var(--blue);
    font-size: 1.2rem;
    display: inline-block;
  }
  .hyper__link:hover{
    cursor: pointer;
    text-decoration: none !important;
    color: var(--green);
  }
  /*MEDIA QUREIES******************************/
  @media only screen and (max-width: 568px){
  .project__image__popup{
    width: 100%; 
  }
}
@media only screen and (max-width: 568px){
  .break{
    background: #fff;
    max-width: 90vw;
    visibility: visible;
  }
}

/**Media QUERIES for projects on landscape mode*/
@media(max-height: 500px){
  .popupbox__wrapper{
    max-height: 100vh;
  }
  .project__image{
    max-width: 80%;
  }
  .project__image__popup{
    width: 12%; 
  }
}